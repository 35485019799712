.container {
  color: var(--font-color);
  background-color: var(--timeline-background-color);
  overflow:auto;
  height:100vh;
  width:100vw;
}

.projectBox {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  height:250px;
  border-radius:5px;
  margin:15px;
  float:left;
  display:flex;
  align-items: flex-end;
  cursor: pointer;
}

.projectBox h2{
  font-weight: normal;
  color: rgb(224, 224, 224);
  padding:15px;
  font-size:18px;
  width:100%;
  background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
}

.sidebar {
  background: var(--media-pool-background-color);
  width:calc(100% - 60px);
  padding:30px;
}

.new, .downlaod{position: fixed;bottom:30px;right:30px;z-index:99;background:var(--media-pool-background-color);padding:11px 46px 46px 11px;}
.downlaod{padding:12px 45px 45px 12px;}
.new span{font-size:36px;}
.downlaod span{font-size:32px;}

.button{
  background: var(--media-pool-background-color);
  border-radius:25px;
  padding:25px 10px;
  color: var(--font-color);
  display:flex;
  width:100%;
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}  

.main {display: block; width:calc(100% - 100px);padding:50px;min-height:calc(100vh - 100px)}

.vbar{display:flex;align-items: center;width:100%;margin-bottom:40px;}
.logo{width:65px;padding-top:10px;}
.vbar h1{font-weight: normal;font-size:26px;}

.btn, .active {color: var(--font-color);display:flex;align-items: center;text-decoration: none;padding:15px 20px;background:rgba(0, 0, 0, 0.151);border-radius:100px;margin:15px 0px;}
.btn span, .active span{margin-right:10px;}
.active{background:rgba(68, 68, 68, 0.151);}

.mediaList {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: auto;
}
.card {
  background-color: var(--media-pool-card-background-color);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding:0px;
  gap: var(--spacing);
  margin-bottom: 20px;
}
.card li{width:100%;}
.imageProperties {
  float: left;
  max-width: 300px;
  width:100%;
  border-radius:5px;
  overflow:hidden;
  height: 150px;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-right: 30px;
}

.process {display:grid;
grid-template-columns: 2fr 7fr 1fr;position:relative; top:90px;align-items: center;gap: 20px;}

.progressBar {
  width:100%;
}

::-webkit-progress-bar{background-color:rgb(255, 80, 11);}

h3{font-weight: normal; position: relative;top:60px;}

.label, .render {
  color: var(--font-color);
}

@media (min-width: 1100px) {
  .sidebar {
    height: calc(100vh - 60px);
  }
  .projectBox{
      width:calc((100% / 3) - 30px);
  }
  .container {
      display:grid;
      grid-template-columns: 1fr 4fr;
    }
  .login{
      display:flex;
      align-items: center;
  }
}