.container {
  /* padding-left: 80px;
  padding-right: 80px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* grid-template-areas:
    "detail mediaPlayer"
    "mediaPool mediaPool "
    "buttonGroup buttonGroup";

  grid-template-columns: 350px minmax(0, 1fr) ;
  grid-template-rows: minmax(0, 1fr) 200px 150px; */
  margin-top: -30px;
  height: 82vh;
}

.generate_btn_group {
  grid-area: buttonGroup;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.regen_btn {
  color: #5A5AFF;
  font-size: 16px;
  width: 208px;
  border-radius: 45px;
  border: 1px solid #5A5AFF;
}
.star {
  margin-right:10px;
}


.skip_btn {
  font-size: 16px;
  width: 106px;
  margin-right: 10px;
  border-radius: 70px;
}

.generate_btn {
  width: 160px;
  background-color: #5A5AFF;
  color: #fff;
  font-size: 16px;
  border-radius: 70px;
}


.detail {
  grid-area: detail;
  margin: 25px 0px 15px 0;
  padding: 15px;
  background-color: #fff;
}
.detail_title {
  height: 40px;
  background-color: #F7F7F8;
  padding: 10px;
  display: flex;
  align-items: center;
}
.detail_name {
  font-weight: 700;
}
.detail_content {
  margin-top: 20px;
}
.star2 {
  margin: 20px 0 0 30px;
}


@media (max-width: 1100px) {
}
