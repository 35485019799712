.container {
    color: var(--font-color);
    background-color: var(--timeline-background-color);
    width:100vw;
    overflow:auto;
    height:100vh;
  }

.projectBox {
    background-color: var(--media-pool-background-color);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    height:250px;
    width:100%;
    border-radius:5px;
    margin:15px 0px;
    float:left;
    display:flex;
    align-items: flex-end;
    cursor: pointer;
    overflow:hidden;
}

.boxShadow{
  width:100%;
  background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  display:grid;
  grid-template-columns: 7fr 1fr 1fr;
}

.projectBox h2{
    font-weight: normal;
    color: rgb(224, 224, 224);
    padding:15px;
    font-size:18px;
}

.projectBox button{background:transparent;padding:0px;margin:0px;width:auto;height:auto;}
.projectBox span{font-size:20px;}



.popup {position: fixed; top:0px; right:0px; width:100vw;height:100vh;background:rgba(0, 0, 0, 0.308);z-index:999;display:flex;align-items: center;vertical-align: center;}
.popup h2{font-weight: normal;}
.popupClose {float:right;margin-top:-40px;margin-right:-10px;}
.popup form{padding-top:20px;}
.popup button{margin-bottom:-20px;}
.popup form div{display:grid;grid-template-columns: 1fr 4fr;column-gap: 20px;margin-bottom:20px;align-items: center;}
.popup form input{background:transparent;border:0px;border-bottom:1px solid #525252;padding:10px;color:#fff;}
.popupContainer {width:100%;max-width: 500px; background:var(--media-pool-background-color);padding:30px;border-radius:5px;margin:auto;box-shadow:0px 0px 8px #000;}

.new, .downlaod{position: fixed;bottom:30px;right:30px;z-index:99;background:var(--media-pool-background-color);padding:11px 46px 46px 11px;}
.downlaod{padding:12px 45px 45px 12px;}
.new span{font-size:36px;}
.downlaod span{font-size:32px;}

.button{
    background: var(--media-pool-background-color);
    border-radius:25px;
    padding:25px 10px;
    color: var(--font-color);
    display:flex;
    width:100%;
    align-items: center;
}

::-webkit-scrollbar {
    width: 30px;
    height: 10px;
  }  

.main {display: block; width:calc(100% - 100px);padding:20px 50px 50px 50px;min-height:calc(100vh - 100px)}

.progress1 {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.main_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22%;
  flex-direction: column;
}
.chat_page {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.generate_page {
  margin-top: 20px;
  width: 100%;
}
.chat_history {
  min-height: 35vh;
  max-height: 60vh;
  overflow: scroll;
  width: 80%;
  padding: 0 0px 0 0px;
}
.scene_group {
  display: flex;
  justify-content: center;
}
.generate_scene {
  height: 65vh;
  overflow: scroll;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.main_div1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;

}
.project_name {
  color: #C5C5C5;
  text-align: center;
  font-size: 60px;
  width: 695px;
  height: 72px;
  border: none;
  padding: 20px;
  border-radius: 10px;
}
.project_name:focus-visible {
  outline: none;
}
.skip_btn {
  font-size: 16px;
  width: 106px;
  margin-right: 10px;
}
.next_btn {
  width: 106px;
  background-color: #5A5AFF;
  color: #fff;
  font-size: 16px;
  border-radius: 70px;
}
.generate_btn {
  width: 160px;
  background-color: #5A5AFF;
  color: #fff;
  font-size: 16px;
  border-radius: 70px;
}
.star {
  margin-right:10px;
}
.btn_group {
  position: absolute;
  bottom: 60px;
  right: 50px;
}
.generate_btn_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.step {
  margin: 5px;
}
.help {
  font-size: 26px;
  font-weight: 600;
  margin-top: 10px;
}
.textareaclass {
  width: 100%;
  display:block;
  padding: 15px;
  border-radius: 15px;
  box-sizing: border-box;
  border: 1px solid #A2A0A0;
  font-size: 18px;
  color: #536471;
  font-weight: 510;
  resize: vertical;
}
.text {
  width: 100%;
  height: 40px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #A2A0A0;
  font-size: 18px;
  color: #536471;
  font-weight: 510;
}
.text:focus-visible {
  outline: none;
}

.chat_input {
  position: relative;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.up {
  position: absolute;
  right: -43px;
}
.up:hover {
  cursor: pointer;
}
.chat_element {
  margin-top: 15px;
  display: flex;
  width: 100%;
}
.chat_avatar {
  width: 28px;
  height: 28px;
}
.chat_name {
  margin-left: 15px;
  font-size: 16px;
  font-weight: 700;
}
.chat_content {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 510;
  color: #4F4F4F;
  overflow-wrap: anywhere;
}

.generating {
  height: 80vh;
  display: flex;
}
.generating_content {
  margin: auto;
  position: relative;
  transform: translate(0,-100px);
}
.generating_text {
  margin: auto;
  color: #343434;
  font-size: 50px;
  position: absolute;
  top: 35%;
}
.generating_img {
  margin-right: 60px;
  margin-bottom: 20px;
}
.generating_img2 {
  margin-left: 60px;
  margin-bottom: 50px;
}

.recent_projects_icons {
  color: #616C79;
  float: right;
}
.recent_projects_icons span {
  margin-right: 15px;
}

.active_view {
  color: #5A5AFF;
}
.regen_btn {
  color: #5A5AFF;
  font-size: 16px;
  width: 208px;
  border-radius: 45px;
  border: 1px solid #5A5AFF;
}
.generate_icon {
  color: #5A5AFF;

}
.scene {
  margin: 10px;
  border: 1px solid #A2A0A0;
  width: 320px;
  height: 280px;
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
  overflow-y: scroll;
}

.scene_title {
  font-weight: 700;
  font-size: 16px;
}
.scene_content {
  font-weight: 510;
  font-size: 16px;
}
.scene_content:focus-visible {
  outline: none;
}

  @media (min-width: 1100px) {
    .sidebar {
      height: calc(100vh - 60px);
    }
    .projectBox{
        width:calc((100% / 3) - 30px);
        margin:15px 15px;
    }
    .container {
        display:grid;
        grid-template-columns: 1fr 4fr;
      }
    .login{
        display:flex;
        align-items: center;
    }
  }

