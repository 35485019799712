.modal_container {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 100;
}
.show {
  display: block !important;
}
.close {
  cursor: pointer;
}
.modal {
  display: none;
  z-index: 9999;
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 8px;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-bottom: 1px solid #D9D9D1;
}
.title {
  font-size: 20px;
  font-weight: 510;
}

.body {
  display: flex;
  height: 58vh;
  padding: 20px 50px 20px 50px;
}
.script {
  width: 45%;
  height: 100%;
}
.scene {
  border: 1px solid #A3A0A0;
  border-radius: 5px;
  height: 80%;
}
.scene_name {
  border-radius: 2px;
  background-color: #F7F7F8;
  padding: 15px;
  margin: 10px;
  font-weight: bold;
}
.scene_content {
  padding-left: 20px;
  padding-right: 20px;
  height: 73%;
}
.scene_content:focus-visible {
  outline: none;
}
.scene_nav {
  border-top: 1px solid #D9D9D1;
  height: 50px;
  display: flex;
  align-items: center;
}
.scene_nav_img {
  margin: 15px;
}
.divider {
  width: 1px;
  height: 22px;
  background-color: #D9D9D1;
}
.scene_btn_group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.regen, .link, .upload {
  border-radius: 10px;
  border: 1px solid #A3A0A0;
  width: 240px;
  height: 88px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}



.media {
  width: 50%;
  height: 100%;
  margin-left: 40px;
  overflow: scroll;
}
.more {
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
  width: auto;
  opacity: 0.5;
  width: 30px;
  height: 30px;
  border-radius: 52px;
  border: 0.5px solid #fff;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  opacity: 1;
  background-color: #5A5AFF;
  color: #FFF;
}
.ul {
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}
.card {
  background-color: var(--media-pool-card-background-color);
  border-radius: 5px;
  /* padding: var(--spacing); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: auto;
  margin-bottom: 20px;
  width: 260px;
  height: 168px;
}

.img {
  width: 260px;
  height: 100%;
  border-radius: 5px;
}
.footer {
  border-top: 1px solid #D9D9D1;

}
.btn_group {
  position: absolute;
  bottom: 20px;
  right: 50px;
}
.cancel_btn {
  background-color: #fff;
  color: #5A5AFF;
  font-size: 16px;
  width: 106px;
  margin-right: 10px;
  border-radius: 70px;
  border: 1px solid #5A5AFF;
}
.save_btn {
  width: 160px;
  background-color: #5A5AFF;
  color: #fff;
  font-size: 16px;
  border-radius: 70px;
}
@media (max-width: 1100px) {
}
