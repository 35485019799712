.container {
    color: var(--font-color);
    background-color: var(--timeline-background-color);
    min-height:100vh;
    width:100vw;
  }

.logo {
    background: var(--media-pool-background-color);
    border-radius:200px;
    padding:15px;
    max-width: 60px;
    margin-bottom:30px;
}

.button{
    background: var(--media-pool-background-color);
    border-radius:25px;
    padding:25px 10px;
    color: var(--font-color);
    display:flex;
    width:100%;
    align-items: center;
}

.loginBox{width:calc(100% - 60px);display:block;max-width:360px;padding:30px;margin:auto;}

.loginBox a{display:block;text-align:center;}

.google{
    width:30px;margin-right:10px;
}
  
  @media (min-width: 1100px) {
    .container {
        display:grid;
        grid-template-columns: 1fr 1fr;
        background-image: url('./img/background.png');
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
      }
    .login{
        display:flex;
        align-items: center;
    }
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  * {
    box-sizing: border-box;
  }
  
  /* style the container */
  .container {
    position: relative;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px 0 30px 0;
  } 
  
  /* style inputs and link buttons */
  input,
  .btn {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    margin: 5px 0;
    opacity: 0.85;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    text-decoration: none; /* remove underline from anchors */
  }
  
  input:hover,
  .btn:hover {
    opacity: 1;
  }
  
  /* add appropriate colors to fb, twitter and google buttons */
  .fb {
    background-color: #3B5998;
    color: white;
  }
  .fbbtn{
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    margin: 5px 0;
    opacity: 0.85;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    text-decoration: none;
    background-color: #3B5998;
    color: white;
  }
  .fafa-facebookfa_fw{
    background-color: #3B5998;
    color: white;
    background-color: #3B5998;
    color: white;
  };
  .twitter {
    background-color: #55ACEE;
    color: white;
  }
  
  .google {
    background-color: #dd4b39;
    color: white;
  }
  
  /* style the submit button */
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* Two-column layout */
  .col {
    float: left;
    width: 50%;
    margin: auto;
    padding: 0 50px;
    margin-top: 6px;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* vertical line */
  .vl {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    border: 2px solid #ddd;
    height: 175px;
  }
  
  /* text inside the vertical line */
  .vl_innertext {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 8px 10px;
  }
  
  /* hide some text on medium and large screens */
  .hide-md-lg {
    display: none;
  }
  
  /* bottom container */
  .bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
  }
  
  /* Responsive layout - when the screen is less than 650px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 650px) {
    .col {
      width: 100%;
      margin-top: 0;
    }
    /* hide the vertical line */
    .vl {
      display: none;
    }
    /* show the hidden text on small screens */
    .hide-md-lg {
      display: block;
      text-align: center;
    }
  }