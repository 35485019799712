* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
  --spacing: 15px;
  --border-radius: 5px;
  --timeline-background-color: #F7F7F8;
  --media-pool-background-color: #FFFFFF;
  --media-pool-card-background-color: #F7F7F8;
  --media-player-background-color: #F7F7F8;
  --font-color: #000000;
  --background-color: #F7F7F8;
  --icon-background-color: #F7F7F8;
  --icon-color: #838383;
  --icon-background-hover-color: #030303;
  --scrollbar-color: #838383;
  --highlight-color: #fff;
  --black-color: #000;
  overflow-x: hidden;
  user-select: none;
}
.react-beautiful-dnd-draggable {
  transition: transform 0.2s;
}
button {
  height: 50px;
  width: 50px;
  background: var(--icon-background-color);
  border-radius: 100%;
  padding: 5px;
  border: none;
  cursor: pointer;
  transition: 0.25s;
}

button:hover {
  /* background: var(--icon-background-hover-color); */
}

button:focus {
  box-shadow: 0px 0px 5px 2px #cacaca;
  border-radius: 100px;
  outline: none;
}

::-webkit-scrollbar-corner {
  background: var(--timeline-background-color);
}

::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: var(--border-radius);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 15px 15px var(--scrollbar-color);
  border: solid 10px transparent;
  border-radius: 100px;
}

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.material-icons {
  color: var(--font-color);
}

.draggedOn {
  outline: 2px dashed #313131;
  outline-offset: -10px;
}

@media (max-width: 1100px) {
  button {
    height: 25px;
    width: 25px;
    background: var(--icon-background-color);
    border-radius: 100%;
    padding: 5px;
    border: none;
    cursor: pointer;
  }
  button .material-icons {
    font-size: 14px;
  }
}
