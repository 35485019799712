.container {
  background: var(--timeline-background-color);
  overflow: auto;
  display: flex;
  justify-content: space-between;
}
.search {
 color: #4F4F4F;
 max-width: 500px; 
 display: flex;
 justify-content: space-between;
}
.search_input {
  width: 100% ;
  border: none;
  color: #4F4F4F;
  height: 16.71px;
  padding: 5px;
  background-color: #F7F7F8;
  font-size: 16px;
}
.search_input:focus-visible {
  outline: none !important;
}
.headerGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.new_btn {
  background-color: #5A5AFF;
  border-radius: 45px;
  width: 160px;
  color: #F7F7F8;
  font-size: 16px;
  height: 45px;
}
.star {
  margin-right:10px;
}
.notification {
  margin-left: 15px;
  margin-right: 15px;
}