.container {
  grid-area: mediaPool;
  padding: 15px 0 15px 0;
  display: flex;
  z-index: 5;
}

.mediaList {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: visible;
  overflow-x: scroll;
  scrollbar-color: pink white;
  scrollbar-width: thin;
  /* height: 180px; */
}

.hbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #E6EAEC;
  border-radius: 5px;
  width: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.addFiles {
  width: 50px;
  height: 50px;
}

.card {
  background-color: var(--media-pool-card-background-color);
  border-radius: 5px;
  /* padding: var(--spacing); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-right: 20px;
  width: 250px;
  height: 100%;
}

.img {
  width: 250px;
  height: 100%;
  border-radius: 5px;
}
.active {
  border: 4px solid #7878FF;
}
.more {
  position: absolute;
  top: 10px;
  right: 10px;
  height: auto;
  width: auto;
}
.length {
  position: absolute;
  width: 28px;
  height: 12px;
  opacity: 0.5;
  background-color: #000;
  border-radius: 2px;
  font-size: 7px;
  color: #FFF;
  text-align: center;
  right: 10px;
  bottom: 10px;
}
.arrowGroup {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #D9D9D9;
}
.west {
  font-size: 18px;
  width:50%;
  text-align: center;
  border-right: 1px solid #D9D9D9;
}
.east {
  font-size: 18px;
  width: 50%;
  text-align: center;
}
.ul {
  display: flex;
  align-items: center;
}
.control {
  display: none;
  top: -31px;
  right: -145px;
  background-color: #FFF;
  position: absolute;
  width: 150px;
  height: 180px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  color: #536471;
  z-index: 10000;
}
.popup {
  display: block;
}
.control_btn {
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  margin: 15px;
}
.control_icon {
  font-size: 18px;
  margin-right: 8px;
}
.control_btn:hover {
  color: #5A5AFF;
}


.cardCaption {
  color: var(--font-color);
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 12px;
  max-width: calc(100% - 100px - var(--spacing));
}

.title {
  color: var(--font-color);
  font-weight: 400;
  font-size: 26px;
  line-height: 50px;
}

.button {
  align-self: flex-end;
  padding: 0px;
  margin: 0px -10px -10px 0px;
  height: auto;
  width: auto;
}

.button:hover {
  background: transparent;
}

.button span {
  font-size: 16px;
  color: #3a3a3a;
}

.button:hover span {
  font-size: 16px;
  color: #686868;
}
.loader {
  color:var(--font-color);
  font-size: large;
}
.left_arrow {
  width: 35px;
  height: 35px;
  border-radius: 70px;
  background-color: rgba(255,255,255, 0.6);
  box-shadow: -3px 3px 3px rgba(0,0,0, 0.2);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 130px;
  margin-top: 70px;
  z-index: 1;
}
.right_arrow {
  width: 35px;
  height: 35px;
  border-radius: 70px;
  background-color: rgba(255,255,255, 0.6);
  box-shadow: 3px 3px 3px rgba(0,0,0, 0.2);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 60px;
  margin-top: 70px;
  z-index: 1;
}