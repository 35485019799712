.container {
  min-height: 35vh;
  background: #FFF;
  padding: 0px 0px 0px 0px;
  overflow: auto;
}

.tracks {
  position: relative;
  min-height: 100%;
}

.timebar {
  width: 100%;
  display: flex;
}

.time {
  color: var(--font-color);
  font-size: 14px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.indicator {
  background: #000;
  width: 4px;
  height: 100%;
  margin-left: 7px;
  position: relative;
}
.highlight {
  width: 10px;
  height: 20px;
  background-color: #FFF;
  border: 4px solid #000;
  margin-bottom: 8px;
  position: relative;
  border-bottom: none;
}
.highlight:after {
  position: absolute;
  display: block;
  width: 9.07px;
  height: 9.07px;
  bottom: -7px;
  
  border: none;
  border-bottom: 4px solid #000 ;
  border-right: 4px solid #000 ;
  left: -2px;
  rotate: 45deg;
  z-index: -1;
  content: "";
}

.pointer { 
  height: 30vh;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
}

.track {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px dashed #383838;
  align-items: center;
  background: #FFF;
}

.card {
  border-radius: 2px;
  transition: box-shadow 0.25s, border-color 0.25s;
}

.fullCard {
  display: inline-block;
  width: auto;
  height: auto;
}

.keyframeCard {
  width: 100%;
  background: #202020;
  border-radius: 2px;
  height: 20px;
}

.keyframeBtn,
.keyframeBtn:hover {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 0px;
  margin-left: -2.55px;
  margin-top: 7px;
  padding: 0px;
  transition: 0s;
  position: absolute;
}

.ruler {
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 1;
  align-items: baseline;
}

.s10 {
  text-align: center;
  position: relative;
  padding-top: 10px;
}

.sec {
  border-right: 1px solid #383838;
  top: 0;
  height: 6px;
  position: absolute;
}

.sec:nth-of-type(1) {
  left: 20%;
}

.sec:nth-of-type(2) {
  left: 40%;
}

.sec:nth-of-type(3) {
  left: 60%;
}

.sec:nth-of-type(4) {
  left: 80%;
}

.sec:nth-of-type(5) {
  height: 14px;
  right: 0px;
}

.rulerCon {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.move {
  cursor: move !important;
}
